import { Layout, SEO } from "components"
import * as React from "react"
import "./Privacy-Policy.scss"

interface IPrivacyPolicyProps {}

const PrivacyPolicy: React.FunctionComponent<IPrivacyPolicyProps> = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="Do you want to know the benefits of using a goals-based pyramid performance review for your organisation? Check out our article."
      />
      <div className="Privacy-policy-section">
        <h1 className="header">Privacy Policy</h1>
        <p className="subtext">Last Updated: September 22, 2020</p>
        <p className="teamble-privacy-policy">
          This privacy policy (“Policy”) describes how Teamble Technology Inc.
          and its related companies (jointly referred to as “Teamble”, “we”,
          “us” or “ours”) collect, use and share your personal information. This
          Policy covers the information we collect about you when you use any of
          our websites (including{" "}
          <a className="link" href="https://teamble.com">
            teamble.com
          </a>
          , the “Site”), products and services, or otherwise interact with us,
          unless a different policy is referenced. We refer to all of our
          products, services and websites (including the Site) as “Services” in
          this Policy. This Policy does not apply to websites that refer to
          different privacy statements. Recent updates to this Policy reflect
          changes in data protection law. In addition, we have worked to make
          the Policy clearer and more understandable. We may further revise this
          Policy at any time. If we make any changes, we will post any such
          changes on this page and revise the ‘Last Updated’ date above.
        </p>
        <h2 className="header-two">Table of contents</h2>
        <div className="table-of-contents">
          <ul>
            <li>Who We Are And How To Contact Us</li>
            <li>Lodging A Complaint</li>
            <li>What Personal Information We Collect And How</li>
            <li>How We Use Your Personal Information</li>
            <li>How We Share Your Personal Information</li>
            <li>How We Store And Secure Your Personal Information</li>
            <li>How Long We Keep Your Personal Information</li>
            <li>How To Access And Control Your Personal Information</li>
            <li>Other Important Privacy Information</li>
          </ul>
        </div>
        <h2 className="header-two">Who are we and how to contact us</h2>
        <div className="contact-section">
          <p>
            Teamble Technology Inc. is the controller of your personal
            information for the purposes of this Policy. You can contact us
            using the following details:
          </p>
          <div className="address-info">
            <div>Address</div>
            <a className="link">
              448 W 37th St, <br />
              New York, NY 10018, <br />
              United States of America
            </a>
          </div>
          <div className="address-info">
            <div>Email</div>
            <div className="contact-info">
              <a className="link" href="mailto:privacy@teamble.com">
                privacy@teamble.com
              </a>
            </div>
          </div>
          <div className="address-info">
            <div>Telephone</div>
            <div className="link">
              <a className="link" href="tel:+18885623981">
                +1 (888) 562-3981
              </a>
            </div>
          </div>

          <div>
            You may also contact our Data Protection Officer (DPO) directly at{" "}
            <a className="link" href="mailto:dpo@teamble.com">
              dpo@teamble.com
            </a>
            .
          </div>
        </div>
        <h2 className="header-two">Lodging a complaint</h2>
        <div className="contact-section">
          <div>
            If you would like to make a complaint regarding this Policy or our
            practices in relation to your personal information, please contact
            us at:
            <a className="link" href="mailto:privacy@teamble.com">
              privacy@teamble.com
            </a>
            .
          </div>

          <div className="complaint-section">
            We will reply to your complaint as soon as we can.
          </div>

          <div>
            If you feel that your complaint has not been adequately resolved,
            please note that data protection law gives you the right to contact
            your local data protection supervisory authority.
          </div>
        </div>
        <h2 className="header-two">
          What personal information we collect and how
        </h2>
        <div className="information-collection">
          <div>
            We collect information about you in a number of ways described
            below.
          </div>

          <div>
            <div>Information you provide to us:</div>
            <ul>
              <li>
                When you sign up for our Services, set preferences, modify your
                profile or make purchases through the Services, we will collect
                your name, email address, phone number, fax number, billing
                address, postal address, username, password, job title,
                demographic information (such as your gender pronoun and
                occupation), as well as any other information you directly give
                us through the Services.
              </li>
              <li>
                The Services also include our customer support, where you may
                choose to submit information regarding a problem you are
                experiencing with a Service. Whether you designate yourself as a
                technical contact, open a support ticket, speak to one of our
                representatives directly or otherwise engage with our support
                team, you will be asked to provide contact information, a
                summary of the problem you are experiencing, and any other
                documentation, screenshots or information that would be helpful
                in resolving the issue.
              </li>
            </ul>
          </div>
          <p>(together, “Identity Data”).</p>

          <div>
            Information submitted by you through your use of the Services: We
            collect information that you input into the Services or otherwise
            provide directly to us. This includes information that you post,
            send, receive and share as part of the Services, such as feedback,
            development goals, and participation in 'pulse check' surveys
            (“Usage Data”).
          </div>

          <div>
            Payment information: We collect certain payment and billing
            information when you register for certain paid Services. For
            example, we ask you to designate a billing representative, including
            name and contact information, upon choosing a paid offering. You
            might also provide payment information, such as payment card
            details, which we collect via secure payment processing services
            (“Payment Data”).
          </div>

          <div>
            Information we source from others: We may receive information about
            you from other sources. We may combine this information with the
            information we collected directly from you or through your use of
            the Services.
          </div>

          <div>
            Information collected through hosting platforms: Many of our
            Services integrate with other online platforms (such as Slack and
            Microsoft Teams, collectively referred to as "Platforms"). When
            adding the relevant Services to the Platforms, we request
            permissions which allow sharing of information such as account and
            profile information or settings of the relevant Platform (“Platform
            Data”).
          </div>

          <div>
            Information collected by automated means: We will automatically log
            information about the device you use to access the Services. This
            may include information about computer operating system type,
            browser type, browser language, the website you visited before
            browsing to our Site, pages you viewed, how long you spent on a
            page, access times and information about your use of and actions on
            our Site (“Technical Data”).
          </div>

          <div>
            Cookies: We will collect information using ‘cookies’. Cookies are
            small data files stored on your hard drive by a website. We may use
            both session cookies (which expire once you close your web browser)
            and persistent cookies (which stay on your computer until you delete
            them) to provide you with a more personal and interactive experience
            on our Site. This type of information is collected to make the Site
            more useful to you and to tailor the experience with us to meet your
            special interests and needs. Either we or our affiliates and
            authorized service providers may also use ‘beacons’, which are small
            files embedded onto the pages of our Site. Beacons are used to
            identify each of our pages in order to be analyzed by our system
            tools. You have control over which cookies and beacons we are
            allowed to collect from you through your web browser settings. For
            more information, please visit{" "}
            <a className="link" href="https://www.allaboutcookies.com">
              www.allaboutcookies.com
            </a>{" "}
            (“Cookie Data”).
          </div>

          <div>
            Anonymous data: We will collect “Anonymous Data”, which is data and
            information that does not permit you to be identified or
            identifiable, either alone or when combined with any other
            information available to a third-party. We may create Anonymous Data
            from the data that we receive about you and other individuals whose
            data we collect. Anonymous Data might include analytics information
            and information collected by us using cookies and other third-party
            software, such as software that monitors how you and other users
            interact with our site.
          </div>
        </div>
        <h2 className="header-two">How we use your personal information</h2>
        <div className="legal-basis">
          <p>
            We will only use your personal information for the purposes for
            which we collected it as listed below, unless we reasonably consider
            that we need to use it for another reason and that reason is
            compatible with the original purpose. If we need to use your
            personal information for an unrelated purpose, we will update this
            Policy and we will explain the legal basis which allows us to do so.
          </p>

          <div>
            <div>
              In respect of each of the purposes for which we use your personal
              information, data protection law requires us to ensure that we
              have a ‘legal basis’ for that use. Most commonly, we will rely on
              one of the following legal bases:
            </div>

            <ul>
              <li>
                Where we need to perform our contractual obligations to you
                (“Contractual Necessity”).
              </li>
              <li>
                Where it is necessary for our legitimate interests, and
                fundamental rights do not override those interests (“Legitimate
                Interests”).
              </li>
              <li>
                Where we need to comply with a legal or regulatory obligation
                (“Compliance with Law”).
              </li>
              <li>
                Where we have your specific consent to carry out the processing
                for the purpose in question (“Consent”).
              </li>
            </ul>
          </div>

          <p>
            Generally, we will not rely on your Consent as a legal basis for
            using your personal information other than in the context of
            marketing communications.
          </p>
        </div>
        <div className="processing-purposes">
          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Create and manage your account to use the Services.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Categories of personal information
              </h5>
              <div className="categories-list">
                Identity Data · Usage Data · Platform Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Contractual Necessity.
              </div>
            </div>
          </div>

          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Process your payments for the Services.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Categories of personal information
              </h5>
              <div className="categories-list">
                Identity Data · Platform Data · Payment Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Contractual Necessity.
              </div>
              <br />
              <div className="legal-basis-description">
                Compliance with Law.
              </div>
            </div>
          </div>

          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Prevent fraud and protect our Services.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Categories of personal information
              </h5>
              <div className="categories-list">
                Identity Data · Platform Data · Technical Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Legitimate Interests – we have a legitimate interest in ensuring
                the ongoing security and proper operation of our Services and
                associated IT services and networks.
              </div>
            </div>
          </div>
          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Troubleshooting and customer support.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Categories of personal information
              </h5>
              <div className="categories-list">
                Identity Data · Usage Data · Platform Data · Technical Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Contractual Necessity.
              </div>
            </div>
          </div>
          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Operate, monitor and improve our Services.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Categories of personal information
              </h5>
              <div className="categories-list">
                Identity Data · Technical Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Legitimate Interests – we have a legitimate interest in
                monitoring and ensuring the proper operation of our Services,
                and in improving our Services to deliver a better experience to
                you and our other customers.
              </div>
            </div>
          </div>
          <div className="purpose-card">
            <div className="purpose-header">
              <div className="purpose-title">Purpose</div>
              <div className="purpose-description">
                Operate, monitor and improve our Services.
              </div>
            </div>
            <div className="purpose-categories">
              <h5 className="categories-title">
                Send marketing communications to you.
              </h5>
              <div className="categories-list">
                Identity Data · Platform Data · Cookie Data
              </div>
            </div>
            <div className="purpose-legal-basis">
              <h5 className="legal-basis-title">Legal basis</h5>
              <div className="legal-basis-description">
                Legitimate Interests – we have a legitimate interest in
                providing you with updates on our Services and related offers
                where you have purchased or shown interest in similar services
                from us.
                <br />
                <br />
                Consent.
              </div>
            </div>
          </div>
        </div>
        <div className="process-info">
          Where we need to process your personal information either to comply
          with law or to perform our contractual obligations, and you fail to
          provide that information when requested, we may not be able to provide
          you with all functionalities of the Services.
        </div>
        <h2 className="header-two">How we share your personal information</h2>
        <div className="share-info">
          The md-table below describes who we may share your personal
          information with and why we share it.
          <br />
          <br />
          Where we do so, we always ensure that the recipients of your personal
          information have signed up to robust provisions to ensure the security
          and integrity of your personal information.
        </div>
        <div className="recipients-section">
          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">Other users of the Services.</div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                You can create content on the Services which may contain
                information about you, and grant permission to others to see,
                share, edit, copy and download that content based on settings
                you or your administrator (if applicable) select.
              </div>
              <div className="reason-description">
                For example, when you send a direct feedback to a teammate on
                the Services, we display your profile picture and name next to
                your feedback so that the recipient with access to the feedback
                can understand who sent the feedback.
              </div>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">
                Your employer and/or IT administrator.
              </div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                If you register or access the Services using an email address
                with a domain that is owned by your employer or organization,
                and such organization wishes to establish an account or site,
                certain information about you including your name, profile
                picture, contact information, content and past use of your
                account may become accessible to that organization’s
                administrator and other Service users sharing the same domain.
              </div>
              <div className="reason-description">
                If you are an administrator for a particular site or group of
                users within the Services, we may share your contact information
                with current or past Service users, for the purpose of
                facilitating Service-related requests.
              </div>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">Our services providers.</div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                We may share your personal information with our service
                providers that help us operate our Services by providing a range
                of services to us, such as:
              </div>
              <ul className="reason-list">
                <li>
                  Cloud computing, data storage, hosting, backup and IT
                  maintenance (such as Amazon Web Services Inc.);
                </li>
                <li>
                  Customer success and customer support (such as Zendesk Inc.);
                </li>
                <li>Payments processing (such as Stripe Inc.); and</li>
                <li>
                  Transactional e-mail notifications of our services and
                  communications (such as Twilio Inc.).
                </li>
              </ul>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">Our professional advisers.</div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                We may share your personal information with our lawyers,
                bankers, auditors and insurers where necessary for them to
                provide professional advice to us.
              </div>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">Data protection authorities.</div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                In certain circumstances, we may be under an obligation to share
                your personal information with data protection authorities in
                some territories, for example to report on our data processing
                activities.
              </div>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">Other public authorities.</div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                We may also be under an obligation to share your personal
                information with other public authorities under certain
                circumstances to comply with applicable law, for example in
                connection with our tax reporting.
              </div>
            </div>
          </div>

          <div className="recipient-card">
            <div className="recipient-header">
              <div className="recipient-title">Category of recipients</div>
              <div className="recipient-name">
                In the event of a sale of our business.
              </div>
            </div>
            <div className="recipient-reason">
              <h5 className="reason-title">Reasons for sharing</h5>
              <div className="reason-description">
                We may share your personal information when we do a business
                deal, or negotiate a business deal, involving the sale or
                transfer of all or a part of our business or assets.
              </div>
            </div>
          </div>
        </div>
        <div className="data-protection-section">
          <div className="data-protection-intro">
            We are a U.S.-based company. Although we do not have an
            establishment within the European Economic Area (“Europe”), because
            we target the Services at individuals who are located in Europe,
            European data protection laws apply to us.
          </div>

          <div className="data-protection-compliance">
            For this reason, we take appropriate steps to ensure that when we
            process your personal information, we do so in accordance with
            European data protection laws.
          </div>

          <div className="data-protection-transfer">
            In addition, where we transfer your personal information to any
            recipients outlined above, we also take appropriate steps to ensure
            that we have implemented appropriate safeguards in accordance with
            European data protection laws to ensure that such protection is
            maintained and is not undermined as a result of that disclosure.
            These may include the following:
          </div>

          <ul className="data-protection-list">
            <li className="data-protection-item">
              Adequacy decision: We may transfer your Personal Data to countries
              that have been deemed to provide an adequate level of protection
              for Personal Data by the European Commission. For further details,
              please see{" "}
              <a href="#" className="data-protection-link">
                here
              </a>
              .
            </li>
            <li className="data-protection-item">
              Model Clauses: We may use specific contracts approved by the
              European Commission, which give Personal Data the same protection
              it has in Europe. For further details, please see{" "}
              <a href="#" className="data-protection-link">
                here
              </a>
              . If you wish to obtain further details of those contractual
              arrangements, please contact us at{" "}
              <a
                href="mailto:privacy@teamble.com"
                className="data-protection-email"
              >
                privacy@teamble.com
              </a>
              .
            </li>
          </ul>
        </div>
        <h2 className="header-two">
          How we store and secure your personal information
        </h2>
        <div className="security-measures-section">
          <div className="security-measure">
            We have put in place appropriate security measures to prevent your
            personal information from being accidentally lost, used or accessed
            in an unauthorized way, altered or disclosed.
            <br />
            We limit access to your personal information to those employees and
            other staff who have a business need to have such access. All such
            people are subject to a contractual duty of confidentiality.
          </div>

          <div className="security-measure">
            We have put in place procedures to deal with any actual or suspected
            breach of your personal information, all of which our employees are
            informed and trained on. In the event of any such breach, we have
            systems in place to work with applicable regulators. In addition, in
            certain circumstances (e.g., where we are legally required to do so)
            we may notify you of breaches affecting your personal information.
          </div>

          <div className="security-measure">
            We use data hosting service providers in the United States to host
            the information we collect about you.
          </div>

          <div className="security-measure">
            While we implement safeguards designed to protect your personal
            information, no security system is impenetrable and due to the
            inherent nature of the Internet, we cannot guarantee that data,
            during transmission through the Internet or while stored on our
            systems or otherwise in our care, is absolutely safe from intrusion
            by others.
          </div>

          <div className="security-measure">
            We also urge you to take additional steps on your own to safeguard
            and maintain the integrity of your information. For example, you
            should never share your account or login information with other
            people and be sure to sign off when finished using a shared or
            public computer.
          </div>
        </div>
        <h2 className="header-two">
          How long we keep your personal information
        </h2>
        <div className="data-retention-section">
          <div className="data-retention-intro">
            We will only retain your personal information for so long as we
            reasonably need to use it for the purposes set out above, unless a
            longer retention period is required by law (for example for
            regulatory purposes).
          </div>

          <div>
            <div className="data-retention-explanation">
              How long we keep information we collect about you depends on the
              type of information, as described in further detail below. After
              such time, we will either delete or anonymize your information or,
              if this is not possible, we will securely store your information
              and isolate it from any further use until deletion is possible.
            </div>

            <ul className="data-retention-list">
              <li className="data-retention-item">
                Account information: We retain your account information for as
                long as your account is active, and for a reasonable period
                thereafter in case you decide to re-activate the Services.
              </li>
              <li className="data-retention-item">
                Information you share on the Services: If your account is
                deactivated or disabled, some of your information and the
                content you have provided will remain in order to allow your
                team members or other users to make full use of the Services.
                For example, we continue to display the feedback you sent to
                your teammates that received them.
              </li>
              <li className="data-retention-item">
                Managed accounts: If the Services are made available to you
                through an organization (e.g. your employer), we retain your
                information for as long as required by the administrator of your
                account.
              </li>
              <li className="data-retention-item">
                Marketing information: If you have elected to receive marketing
                emails from us, we retain information about your marketing
                preferences for a reasonable period of time from the date you
                last expressed interest in our Services, such as when you last
                opened an email from us or ceased using your Teamble account. We
                also retain information derived from cookies and other tracking
                technologies for a reasonable period of time from the date such
                information was collected.
              </li>
            </ul>
          </div>
        </div>
        <h2 className="header-two">
          How to access and control your personal information
        </h2>
        <div className="user-rights-section">
          <div className="user-rights-intro">
            Below is a summary of the rights available to you when it comes to
            your information, how to exercise them, and any limitations.
          </div>

          <div className="user-rights-list">
            <div className="user-rights-title">
              Your rights: Under certain circumstances, you may have the right
              to:
            </div>
            <ul className="user-rights-items">
              <li className="user-rights-item">
                Request access to your personal information. This enables you to
                receive a copy of the personal information we hold about you and
                to check that we are lawfully processing it.
              </li>
              <li className="user-rights-item">
                Request the correction of your personal information. This
                enables you to have any incomplete or inaccurate information we
                hold about you corrected.
              </li>
              <li className="user-rights-item">
                Request the erasure of your personal information. This enables
                you to ask us to delete or remove your personal information
                where there is no good reason for us to continue processing it.
                You also have the right to ask us to delete or remove your
                personal information where you have exercised your right to
                object to processing.
              </li>
              <li className="user-rights-item">
                Object to the processing of your personal information. This
                right exists where we are relying on a ‘Legitimate Interests’ as
                the legal basis for our processing, and there is something about
                your particular situation that makes you want to object to
                processing on this ground. You also have the right to object
                where we are processing your personal information for direct
                marketing purposes.
              </li>
              <li className="user-rights-item">
                Request the restriction of processing of your personal
                information. This enables you to ask us to suspend the
                processing of personal information about you, for example, if
                you want us to establish its accuracy or the reason for
                processing it.
              </li>
              <li className="user-rights-item">
                Request the transfer of your personal information. We will
                provide to you, or a third party you have chosen, your personal
                information in a structured, commonly used, machine-readable
                format. Note that this right only applies to automated
                information that you initially provided consent for us to use or
                where we used the information to perform a contract with you.
              </li>
              <li className="user-rights-item">
                Withdraw your consent. This right only exists where we are
                relying on your express Consent to process your personal
                information. If you withdraw your Consent, we may not be able to
                provide you with access to some functionalities of our Services.
                We will advise you if this is the case at the time you withdraw
                your consent.
              </li>
            </ul>
          </div>

          <div className="user-rights-exercise">
            Exercising your rights: You can exercise some of the rights detailed
            above by logging into the Services and using settings available
            within the Services or your account. Where the Services are
            administered for you by an administrator, you may need to contact
            your administrator to assist with your request. For all other
            requests, you may contact us to request assistance.
          </div>

          <div className="user-rights-limitations">
            Your rights may be limited in certain cases: For example, if
            fulfilling your request would reveal information about another
            person, or if you ask to delete information which we or your
            administrator are permitted by law or have compelling legitimate
            interests to keep. Where you have asked us to share data with third
            parties, for example, by installing third-party apps, you will need
            to contact those third-party service providers directly to have your
            information deleted or otherwise restricted. If you have unresolved
            concerns, you may have the right to complain to a data protection
            authority in the country where you live, where you work, or where
            you feel your rights were infringed.
          </div>

          <div className="user-rights-deactivation">
            Deactivating your account: If you no longer wish to use our
            Services, you or your administrator may deactivate your account with
            us in your account settings. If you are unable to deactivate an
            account through your account settings, please contact us. Please be
            aware that deactivating your account does not delete your
            information; your information remains visible to other Service users
            based on your past participation within the Services. For more
            information on how to delete your information, see above.
          </div>

          <div className="user-rights-communications">
            <div className="communications-title">
              Opting out of communications:
            </div>
            <div className="communications-explanation">
              You may opt out of receiving promotional communications from us by
              using the unsubscribe link within each email or by contacting us
              to have your contact information removed from our promotional
              email list or registration database. Even after you opt out of
              receiving promotional messages from us, you will continue to
              receive transactional messages from us regarding our Services. You
              can opt out of some notification messages in your account
              settings. You may be able to opt out of receiving personalized
              advertisements from other companies who are members of the Network
              Advertising Initiative or who subscribe to the Digital Advertising
              Alliance’s Self-Regulatory Principles for Online Behavioral
              Advertising. For more information about this practice and to
              understand your options, please visit:
              <a href="https://www.aboutads.info" className="privacy-link">
                www.aboutads.info
              </a>
              ,
              <a
                href="https://www.optout.networkadvertising.org"
                className="privacy-link"
              >
                www.optout.networkadvertising.org
              </a>
              , and
              <a
                href="https://www.youronlinechoices.eu"
                className="privacy-link"
              >
                www.youronlinechoices.eu
              </a>
              .
            </div>
          </div>

          <div className="user-rights-cookie-controls">
            <div className="cookie-controls-title">Cookie controls:</div>
            <div className="cookie-controls-explanation">
              Some people prefer not to allow cookies, which is why most
              browsers give you the ability to manage cookies to suit you. In
              some browsers, you can set up rules to manage cookies on a
              site-by-site basis, giving you more fine-grained control over your
              privacy. This means you can disallow cookies from all sites except
              those that you trust. Please consult the documentation that your
              browser manufacturer provides to help with cookie management in
              their product. If you remove or reject our cookies, it could
              affect how our Site works for you.
            </div>
          </div>

          <div className="user-rights-dnt">
            <div className="dnt-title">Send "Do Not Track" Signals:</div>
            <div className="dnt-explanation">
              Some browsers have incorporated "Do Not Track" (DNT) features that
              can send a signal to the websites you visit indicating you do not
              wish to be tracked. Because there is not yet a common
              understanding of how to interpret the DNT signal, our Services do
              not currently respond to browser DNT signals. You can use the
              range of other tools we provide to control data collection and
              use, including the ability to opt out of receiving marketing from
              us as described above.
            </div>
          </div>
        </div>
        <h2 className="header-two">Other important privacy information</h2>
        <div className="ccpa-list">
          <div className="header">
            California Consumer Privacy Act (CCPA) Notice for California
            residents:
          </div>
          <div>
            Effective January 1, 2020, the California Consumer Privacy Act
            (CCPA) allows California residents to request from a business that
            collects personal information to give consumers access to and/or
            deletion of the personal information collected. Terms used in this
            CCPA Notice but not defined here will have the same meaning as
            defined under the CCPA.
          </div>
          <div className="ccpa-rights-section">
            <ol className="ccpa-rights-list">
              <li className="ccpa-rights-item">
                Teamble does not sell your personal information to third
                parties.
              </li>
              <li className="ccpa-rights-item">
                Teamble discloses personal information for business purposes
                only.
                <ul className="ccpa-business-purposes">
                  <li className="ccpa-business-purpose">
                    Business purposes can include such things as: providing our
                    Services, maintaining and servicing accounts, providing
                    customer service, processing or fulfilling orders and
                    transactions, performing analytics and quality control,
                    auditing transactions, researching and testing features and
                    improvements, detecting and preventing fraud and security
                    incidents, debugging or repairing technical errors, and
                    marketing our Services.
                  </li>
                  <li className="ccpa-business-purpose">
                    Teamble may share or allow third parties to collect personal
                    information through our Services for business purposes we’ve
                    described in this Policy.
                  </li>
                </ul>
              </li>
              <li className="ccpa-rights-item">
                Categories of personal information collected and disclosed. The
                categories of personal information collected and disclosed to
                third parties for business purposes by Teamble are listed in the
                “What We Collect” section of this Policy.
              </li>
              <li className="ccpa-rights-item">
                Your rights under CCPA. If you are a California resident using
                the Services, you have the following rights:
                <ul className="ccpa-rights-details">
                  <li className="ccpa-rights-detail">
                    To request the categories of personal information that the
                    business collected about you.
                  </li>
                  <li className="ccpa-rights-detail">
                    To request the categories of personal information that the
                    business disclosed about you for a business purpose.
                  </li>
                  <li className="ccpa-rights-detail">
                    To request deletion of the personal information it has
                    collected from you, subject to certain legal exceptions. For
                    example, when the personal information is necessary to
                    complete a transaction request or to comply with a legal
                    obligation, Teamble may claim an exemption to deletion of
                    your personal information.
                  </li>
                  <li className="ccpa-rights-detail">
                    The right to be protected from discrimination for exercising
                    your CCPA rights. Businesses are prohibited from
                    discriminating against you for exercising your rights under
                    the CCPA, including by:
                    <ul className="ccpa-non-discrimination">
                      <li className="ccpa-non-discrimination-item">
                        Denying you goods or services.
                      </li>
                      <li className="ccpa-non-discrimination-item">
                        Charging you different prices or rates for goods or
                        services, including through the use of discounts or
                        other benefits or imposing penalties.
                      </li>
                      <li className="ccpa-non-discrimination-item">
                        Providing you with a different level or quality of goods
                        or services.
                      </li>
                      <li className="ccpa-non-discrimination-item">
                        Suggesting that you will receive a different price,
                        rate, level, or quality of goods or services.
                      </li>
                    </ul>
                    Nothing prohibits a business from charging a different price
                    or providing a different level or quality of service if the
                    difference is reasonably related to the value provided to
                    the consumer by the consumer’s data.
                  </li>
                  <li className="ccpa-rights-detail">
                    Your rights under CCPA may be exempted as permitted under
                    the statute, particularly if you use the Services as an
                    employee or agent under a business account. Teamble
                    expressly reserves all rights to claim legal exemptions
                    permitted under the CCPA.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div>
            For additional information about this CCPA Notice or to submit a
            CCPA request, please contact us via mail, e-mail or phone number
            listed below in the Contact Information section.
          </div>
          <div className="header">California Online Privacy Protection Act</div>
          <div>
            In compliance with CalOPPA, we have hereby posted this conspicuous
            Policy to the public, indicating the personal information being
            collected and the manner in which it may be disclosed and with whom.
            Accordingly, our users may visit our Site using anonymous browsing,
            this Policy linked in our home page, with the link including the
            word ‘Privacy’ or similar. We also comply with Policy change
            notification to our users, and provide mechanisms that allow our
            users to manage their personal information and personal information.
          </div>
          <div className="header">
            European Union General Data Protection Regulation (GDPR)
          </div>
          <div>
            As of April 2nd, 2020, Teamble is GDPR compliant in how we handle
            customer data. We have taken the following steps to ensure we’re
            compliant with the GDPR.
            <div className="policy-updates-section">
              <ul className="policy-updates-list">
                <li className="policy-updates-item">
                  We have updated this Policy to better explain how we use
                  customer data and how and when we use cookies.
                </li>
                <li className="policy-updates-item">
                  We have updated our Terms of Service to support Explicit
                  Consent and Opt-Out.
                </li>
                <li className="policy-updates-item">
                  We have appointed a dedicated Data Protection Officer (DPO) to
                  handle a variety of issues in accordance with the GDPR
                  requirements. To communicate with our Data Protection Officer,
                  please email{" "}
                  <a href="mailto:dpo@teamble.com" className="policy-email">
                    dpo@teamble.com
                  </a>
                  .
                </li>
                <li className="policy-updates-item">
                  We have implemented some compliance measures to make it easy
                  to handle requests such as deletion or update requests of your
                  personal information. To do so, simply e-mail{" "}
                  <a href="mailto:privacy@teamble.com" className="policy-email">
                    privacy@teamble.com
                  </a>{" "}
                  with your request.
                </li>
                <li className="policy-updates-item">
                  We have conducted more awareness and training with our team
                  around data protection and incident response on potential
                  issues like data breaches.
                </li>
                <li className="policy-updates-item">
                  We have thoroughly audited all of the services that we use to
                  ensure that they are either GDPR-compliant or don’t gain
                  access to personal information. For the third-party services
                  that do handle your personal information, we have been
                  diligent to only allow the minimum amount of critical
                  information required to use the service.
                </li>
              </ul>
            </div>
          </div>
          <div className="header">Our policy towards children</div>
          <div>
            The Services are not directed to individuals under 16. We do not
            knowingly collect personal information from children under 16. If we
            become aware that a child under 16 has provided us with personal
            information, we will take steps to delete such information. If you
            become aware that a child has provided us with personal information,
            please contact our support services.
          </div>
          <div className="header">Responsible disclosure</div>
          <div className="vulnerability-disclosure-section">
            <div className="vulnerability-disclosure-text">
              If you believe you’ve discovered a potential vulnerability, please
              let us know by emailing us at{" "}
              <a
                href="mailto:privacy@teamble.com"
                className="vulnerability-email"
              >
                privacy@teamble.com
              </a>
              . We will acknowledge your email within ten business days. Provide
              us with a reasonable amount of time to resolve the issue before
              disclosing it to the public or a third-party. We aim to resolve
              critical issues within one month of disclosure. Make a good faith
              effort to avoid violating privacy, destroying data, or
              interrupting or degrading the Teamble service. Please only
              interact with accounts you own or for which you have explicit
              permission from the account holder.
            </div>
          </div>
          <div className="security-research-guidelines-section">
            <div className="security-research-intro">
              While researching, we’d like you to refrain from:
            </div>
            <ul className="security-research-list">
              <li className="security-research-item">
                Distributed Denial of Service (DDoS)
              </li>
              <li className="security-research-item">Spamming</li>
              <li className="security-research-item">
                Social engineering or phishing of Teamble employees or
                contractors
              </li>
              <li className="security-research-item">
                Any attacks against Teamble’s physical property or data centers
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
